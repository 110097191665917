<!-- copy of areas -->

<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Ordini</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder"> -->
    <b-overlay rounded="sm" class="overlay-placeholder">
      <b-card>
        <div v-if="loadingItems" class="w-100">
          <SkeletonTable></SkeletonTable>
        </div>
        <b-table
          v-else
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(user_id)="row">
            {{
              row.item.user_id
                ? usersOptions.filter((x) => x.value == row.item.user_id)[0]
                  ? usersOptions.filter((x) => x.value == row.item.user_id)[0]
                      .text
                  : `N/A - id:${row.item.user_id}`
                : `N/A - id:${row.item.user_id}`
            }}
          </template>

          <template #cell(items)="row">
            {{ row.item.items.length }}
          </template>

          <template #cell(dataOrdine)="row">
            {{
              new Date(row.item.dataOrdine).toLocaleDateString("it", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </template>

          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-product
                @click="
                  currentItem = row.item;
                  tmpItem = JSON.parse(JSON.stringify(row.item));
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentItem = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-product"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper  my-3 mx-1">
        <h4 v-if="tmpItem.id && tmpItem.client_name">
          {{ tmpItem.nome }} <span></span>
        </h4>
        <h4 v-else-if="tmpItem.id">
          ID Ordine: {{ tmpItem.id }} <span></span>
        </h4>
        <h4 v-else>Nuovo Ordine</h4>

        <b-row class="mb-2">
          <b-col>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name=""
                  id=""
                  v-model="tmpItem.confirmed"
                  checked
                />
                Ordine confermato
              </label>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Cliente">
              <b-form-input v-model="tmpItem.client_name" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Sconto Base">
              <b-form-input type="number" v-model="tmpItem.scontoBase" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div
              v-for="item in tmpItem.items"
              :key="item.id"
              class="flex justify-content-between"
            >
              <span>
                <span>{{ item.number }} x </span>
                {{ item.nome || "NOME PRODOTTO" }}</span
              >
              <span>{{
                (item.price * item.number).toLocaleString("it", {
                  style: "currency",
                  currency: "EUR",
                })
              }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="flex justify-content-end">
              <span class="font-bold"
                >Tot:
                {{
                  Number(tmpItem.tot_price).toLocaleString("it", {
                    style: "currency",
                    currency: "EUR",
                  })
                }}</span
              >
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="mt-5 mb-5">
              <b-button
                v-if="loadingItems"
                squared
                variant="primary"
                size="lg"
                class="big-button"
                disabled
                >Caricamento in corso...</b-button
              >

              <b-button
                v-else
                squared
                variant="primary"
                size="lg"
                class="big-button"
                @click="saveItem"
                >Confirm</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentItem.id)"
    >
      <span v-if="currentItem">Do you really want to delete?</span>
    </b-modal>
  </div>
</template>

<script>
  import AxiosService from "@/axiosServices/AxiosService.js";
  import SkeletonTable from "../../components/SkeletonTable.vue";
  export default {
    name: "Ordini",
    components: { SkeletonTable },
    data() {
      return {
        loadingItems: false,
        items: [],
        fields: [
          { key: "dataOrdine", label: "Data" },
          { key: "user_id", label: "Utente" },
          { key: "client_name", label: "Cliente" },
          { key: "n_items", label: "N Prodotti" },
          { key: "payment_conditions", label: "Condizioni di pagamento" },
          { key: "tot_price", label: "Prezzo Tot" },
          { key: "confirmed", label: "Confermato" },
          { key: "actions", label: "Azioni" },
        ],
        view_lang: null,

        currentPage: 1,
        perPage: 10,
        filter: "",

        tmpItem: {
          id: 0,
          user_id: 0,
          client_id: 0,
          n_items: 0,
          tot_price: 0,
          discount: 0,
          payment_condition: "",
          confirmed: true,
          items: [],
        },
        currentItem: {},

        tmpImage: null,

        showDeleteModal: false,

        usersOptions: [],
      };
    },
    serviceGET: null,
    servicePOST: null,
    servicePUT: null,
    serviceDELETE: null,
    created() {
      this.serviceGET = new AxiosService("Ecommerce/GetOrders");
      this.servicePOST = new AxiosService("Ecommerce/AddOrder");
      this.servicePUT = new AxiosService("Ecommerce/EditOrder");
      this.serviceDELETE = new AxiosService("Ecommerce/DeleteOrder");

      this.resetData();

      this.getData();
      this.getUsers();
    },

    methods: {
      getData() {
        this.loadingItems = true;
        this.resetData();

        this.serviceGET
          .read()
          .then((res) => {
            this.items = res;
          })
          .finally(() => {
            this.loadingItems = false;
          });
      },
      resetData() {
        this.tmpItem = {
          id: 0,
          nome: "",
          scontoBase: 0,
        };
      },
      async saveItem() {
        this.loadingItems = true;
        let item = { ...this.tmpItem };

        if (item.id) {
          this.servicePUT
            .update(item)
            .then(() => {
              this.$root.$emit("bv::toggle::collapse", "sidebar-product");
              this.getData();
              this.$successToast();
            })
            .catch(() => {
              this.$errorToast();
              return false;
            })
            .finally(() => {
              this.loadingItems = false;
            });
        } else {
          this.servicePOST
            .create(item)
            .then((response) => {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-product");
              this.getData();
              this.$successToast();
            })
            .catch(() => {
              this.$errorToast();
              return false;
            })
            .finally(() => {
              this.loadingItems = false;
            });
        }
      },

      deleteItem(id) {
        this.loadingItems = true;
        this.serviceDELETE
          .delete(id)
          .then((response) => {
            if (response) {
              this.items = this.items.filter(
                (currentItem) => id !== currentItem.id
              );
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.loadingItems = false;
          });
      },

      getUsers() {
        this.loadingItems = true;
        const service = new AxiosService("Option/User");
        service
          .read()
          .then((res) => {
            this.usersOptions = res;
          })
          .finally(() => {
            this.loadingItems = false;
          });
      },

      // getClienti() {
      //   this.loadingItems = true
      //   const service = new AxiosService("Option/Client")
      // },
    },
    computed: {
      totalRows() {
        return this.items ? this.items.length : 0;
      },
    },
    watch: {
      filter: {
        handler() {
          this.currentPage = 1;
        },
      },
    },
  };
</script>
